import { MonthPicker, YearPicker } from "@stenajs-webui/calendar";
import { Box, Indent, Row, Spacing, Txt } from "@stenajs-webui/core";
import {
  FlatButton,
  Icon,
  stenaArrowWideRight,
  stenaCalendar,
} from "@stenajs-webui/elements";
import { TextInput, TextInputBox } from "@stenajs-webui/forms";
import { cssColor } from "@stenajs-webui/theme";
import { Popover } from "@stenajs-webui/tooltip";
import * as React from "react";
import { KeyboardEvent, useRef, useState } from "react";
import {
  addZeroToMonth,
  formatYearAndMonthWithDashOnInput,
} from "../../dates/formatters";
import { KeyboardKeyConstant } from "../../utils/KeyboardConstants";

export interface DateRangeInputValues {
  yearFrom: number | string;
  yearTo: number | string;
  monthFrom: number | string;
  monthTo: number | string;
}

type DateRangeInputState = {
  isFromPopoverOpen: boolean;
  isToPopoverOpen: boolean;
  isFromYearPickerOpen: boolean;
  isFromMonthPickerOpen: boolean;
  isToYearPickerOpen: boolean;
  isToMonthPickerOpen: boolean;
  fromTextValue: string;
  toTextValue: string;
};

interface Props {
  setDateRangeValues: (payload: DateRangeInputValues) => void;
  value: DateRangeInputValues;
  error?: boolean;
  errorText?: string;
}

export const DateRangeYearAndMonthInput: React.FC<Props> = ({
  setDateRangeValues,
  value,
  error,
  errorText,
}) => {
  const date = value;

  const [dateRangeInputState, setDateRangeInputState] =
    useState<DateRangeInputState>({
      isFromPopoverOpen: false,
      isToPopoverOpen: false,
      isFromYearPickerOpen: false,
      isFromMonthPickerOpen: false,
      isToYearPickerOpen: false,
      isToMonthPickerOpen: false,
      fromTextValue: "",
      toTextValue: "",
    });

  // From input border
  const borderBottomFromInput = dateRangeInputState.isFromPopoverOpen
    ? `1px solid ${cssColor("--lhds-color-ui-300")}`
    : "unset";
  // To input border
  const borderBottomToInput = dateRangeInputState.isToPopoverOpen
    ? `1px solid ${cssColor("--lhds-color-ui-300")}`
    : "unset";

  const fromInputRef = useRef<HTMLInputElement | null>(null);
  const toInputRef = useRef<HTMLInputElement | null>(null);

  // For both input fields
  const onValueChangeInput = (
    input: {
      isFromInput?: boolean;
      isToInput?: boolean;
    },
    value: string
  ) => {
    const newFromValue = formatYearAndMonthWithDashOnInput(
      value,
      fromInputRef.current!
    );
    const newToValue = formatYearAndMonthWithDashOnInput(
      value,
      toInputRef.current!
    );
    // From input
    if (input.isFromInput) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        fromTextValue: newFromValue,
      }));
      if (
        newFromValue.length >= 5 &&
        fromInputRef.current?.selectionStart! >= 4
      ) {
        setDateRangeInputState((prevState) => ({
          ...prevState,
          isFromYearPickerOpen: false,
          isFromMonthPickerOpen: true,
        }));
      } else {
        setDateRangeInputState((prevState) => ({
          ...prevState,
          isFromYearPickerOpen: true,
          isFromMonthPickerOpen: false,
        }));
      }

      if (newFromValue.length === 7) {
        const yearFrom = Number(newFromValue.slice(0, 4));
        const monthFrom = Number(newFromValue.slice(5, 7));
        setDateRangeInputState((prevState) => ({
          ...prevState,
          isFromPopoverOpen: false,
          isFromYearPickerOpen: false,
          isFromMonthPickerOpen: false,
        }));
        toInputRef.current && toInputRef.current.focus();
        setDateRangeValues({
          yearFrom: yearFrom ?? "",
          monthFrom: monthFrom ?? "",
          yearTo: date.yearTo ?? "",
          monthTo: date.monthTo ?? "",
        });
      }
    }
    // To input
    if (input.isToInput) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        toTextValue: newToValue,
      }));
      if (newToValue.length >= 5 && toInputRef.current?.selectionStart! >= 4) {
        setDateRangeInputState((prevState) => ({
          ...prevState,
          isToYearPickerOpen: false,
          isToMonthPickerOpen: true,
        }));
      } else {
        setDateRangeInputState((prevState) => ({
          ...prevState,
          isToYearPickerOpen: true,
          isToMonthPickerOpen: false,
        }));
      }

      if (newToValue.length === 7) {
        const yearTo = Number(newToValue.slice(0, 4));
        const monthTo = Number(newToValue.slice(5, 7));
        setDateRangeInputState((prevState) => ({
          ...prevState,
          isToPopoverOpen: false,
          isToYearPickerOpen: false,
          isToMonthPickerOpen: false,
        }));
        toInputRef.current && toInputRef.current.blur();
        setDateRangeValues({
          yearFrom: date.yearFrom ?? "",
          monthFrom: date.monthFrom ?? "",
          yearTo: yearTo ?? "",
          monthTo: monthTo ?? "",
        });
      }
    }
  };

  // For all datePickers
  const onValueChangePickers = (
    dateRange: {
      yearFrom?: number;
      monthFrom?: number;
      yearTo?: number;
      monthTo?: number;
    },
    element: {
      isFromYearPicker?: boolean;
      isFromMonthPicker?: boolean;
      isToYearPicker?: boolean;
      isToMonthPicker?: boolean;
    }
  ) => {
    if (element.isFromYearPicker) {
      const monthFrom = dateRangeInputState.fromTextValue.slice(-2);
      const noDashInMonthFrom = monthFrom && !monthFrom.includes("-");

      setDateRangeInputState((prevState) => ({
        ...prevState,
        isFromYearPickerOpen: false,
        isFromMonthPickerOpen: true,
        fromTextValue: `${dateRange.yearFrom}-${
          noDashInMonthFrom ? monthFrom : ""
        }`,
      }));
    }
    if (element.isFromMonthPicker) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isFromMonthPickerOpen: false,
        isFromPopoverOpen: false,
        fromTextValue: `${dateRangeInputState.fromTextValue.slice(
          0,
          5
        )}${addZeroToMonth(dateRange.monthFrom ?? "")}`,
      }));
      toInputRef.current && toInputRef.current.focus();
    }
    if (element.isToYearPicker) {
      const monthTo = dateRangeInputState.toTextValue.slice(-2);
      const noDashInMonthTo = monthTo && !monthTo.includes("-");

      setDateRangeInputState((prevState) => ({
        ...prevState,
        isToYearPickerOpen: false,
        isToMonthPickerOpen: true,
        toTextValue: `${dateRange.yearTo}-${noDashInMonthTo ? monthTo : ""}`,
      }));
    }
    if (element.isToMonthPicker) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isToMonthPickerOpen: false,
        isToPopoverOpen: false,
        toTextValue: `${dateRangeInputState.toTextValue.slice(
          0,
          5
        )}${addZeroToMonth(dateRange.monthTo ?? "")}`,
      }));
    }
    const fromTextYearValueIsNotZero =
      Number(dateRangeInputState.fromTextValue.slice(0, 4)) !== 0
        ? Number(dateRangeInputState.fromTextValue.slice(0, 4))
        : "";
    const fromTextMonthValueIsNotZero =
      Number(dateRangeInputState.fromTextValue.slice(-2)) !== 0
        ? Number(dateRangeInputState.fromTextValue.slice(-2))
        : "";

    const toTextYearValueIsNotZero =
      Number(dateRangeInputState.toTextValue.slice(0, 4)) !== 0
        ? Number(dateRangeInputState.toTextValue.slice(0, 4))
        : "";
    const toTextMonthValueIsNotZero =
      Number(dateRangeInputState.toTextValue.slice(-2)) !== 0
        ? Number(dateRangeInputState.toTextValue.slice(-2))
        : "";
    const yearFrom =
      dateRange.yearFrom && dateRange.yearFrom !== undefined
        ? dateRange.yearFrom
        : date.yearFrom && date.yearFrom !== ""
        ? date.yearFrom
        : fromTextYearValueIsNotZero;

    const monthFrom =
      dateRange.monthFrom && dateRange.monthFrom !== undefined
        ? dateRange.monthFrom
        : date.monthFrom && date.monthFrom !== ""
        ? date.monthFrom
        : fromTextMonthValueIsNotZero;

    const yearTo =
      dateRange.yearTo && dateRange.yearTo !== undefined
        ? dateRange.yearTo
        : date.yearTo && date.yearTo !== ""
        ? date.yearTo
        : toTextYearValueIsNotZero;

    const monthTo =
      dateRange.monthTo && dateRange.monthTo !== undefined
        ? dateRange.monthTo
        : date.monthTo && date.monthTo !== ""
        ? date.monthTo
        : toTextMonthValueIsNotZero;

    setDateRangeValues({
      yearFrom: yearFrom,
      monthFrom: monthFrom,
      yearTo: yearTo,
      monthTo: monthTo,
    });
  };

  // For both input fields
  const textInputValue = (textInputField: {
    isFromInput?: boolean;
    isToInput?: boolean;
  }) => {
    // From input
    if (textInputField.isFromInput) {
      return date.yearFrom && date.monthFrom
        ? `${date.yearFrom ?? ""}${date.yearFrom && "-"}${addZeroToMonth(
            date.monthFrom
          )}`
        : dateRangeInputState.fromTextValue;
    }
    // To input
    if (textInputField.isToInput) {
      return date.yearTo && date.monthTo
        ? `${date.yearTo ?? ""}${date.yearTo && "-"}${addZeroToMonth(
            date.monthTo
          )}`
        : dateRangeInputState.toTextValue;
    }
  };

  // For both input fields
  const handleKeysDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // From year and month text input
    if (
      dateRangeInputState.isFromPopoverOpen &&
      event.key === KeyboardKeyConstant.BACKSPACE &&
      date.monthFrom &&
      date.yearFrom
    ) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        fromTextValue: `${date.yearFrom}${
          date.yearFrom && date.monthFrom && "-"
        }${addZeroToMonth(date.monthFrom)}`,
      }));
      setDateRangeValues({
        yearFrom: "",
        monthFrom: "",
        yearTo: date.yearTo ?? "",
        monthTo: date.monthTo ?? "",
      });
    }

    if (
      dateRangeInputState.isFromPopoverOpen &&
      event.key === KeyboardKeyConstant.BACKSPACE &&
      date.yearFrom
    ) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        fromTextValue: `${date.yearFrom}${
          date.yearFrom && date.monthFrom && "-"
        }${addZeroToMonth(date.monthFrom)}`,
      }));
      setDateRangeValues({
        yearFrom: "",
        monthFrom: "",
        yearTo: date.yearTo ?? "",
        monthTo: date.monthTo ?? "",
      });
    }

    // From input field
    if (
      dateRangeInputState.isFromPopoverOpen &&
      event.shiftKey &&
      event.key === KeyboardKeyConstant.TAB
    ) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isFromPopoverOpen: false,
        isFromYearPickerOpen: false,
        isFromMonthPickerOpen: false,
      }));
    }

    // To year and month text input
    if (
      dateRangeInputState.isToPopoverOpen &&
      event.key === KeyboardKeyConstant.BACKSPACE &&
      date.monthTo &&
      date.yearTo
    ) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        toTextValue: `${date.yearTo}${
          date.yearTo && date.monthTo && "-"
        }${addZeroToMonth(date.monthTo)}`,
      }));
      setDateRangeValues({
        yearFrom: date.yearFrom ?? "",
        monthFrom: date.monthFrom ?? "",
        yearTo: "",
        monthTo: "",
      });
    }

    if (
      dateRangeInputState.isToPopoverOpen &&
      event.key === KeyboardKeyConstant.BACKSPACE &&
      date.yearTo
    ) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        toTextValue: `${date.yearTo}${
          date.yearTo && date.monthTo && "-"
        }${addZeroToMonth(date.monthTo)}`,
      }));
      setDateRangeValues({
        yearFrom: date.yearFrom ?? "",
        monthFrom: date.monthFrom ?? "",
        yearTo: "",
        monthTo: "",
      });
    }
    // To input field
    if (
      dateRangeInputState.isToPopoverOpen &&
      event.shiftKey &&
      event.key === KeyboardKeyConstant.TAB
    ) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isToPopoverOpen: false,
        isToYearPickerOpen: false,
        isToMonthPickerOpen: false,
      }));
    }
  };

  // For both inputs and calendar button
  const onFocus = (element: {
    isCalendarButton?: boolean;
    isFromInput?: boolean;
    isToInput?: boolean;
  }) => {
    if (element.isCalendarButton) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isToPopoverOpen: false,
        isToYearPickerOpen: false,
        isToMonthPickerOpen: false,
      }));
    }
    if (element.isFromInput) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isFromPopoverOpen: true,
        isFromYearPickerOpen: true,
      }));
    }
    if (element.isToInput) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isToPopoverOpen: true,
        isToYearPickerOpen: true,
        isFromPopoverOpen: false,
        isFromYearPickerOpen: false,
        isFromMonthPickerOpen: false,
      }));
    }
  };

  // For both inputs and calendar button
  const onClick = (element: {
    isCalendarButton?: boolean;
    isFromInput?: boolean;
    isToInput?: boolean;
  }) => {
    if (element.isCalendarButton) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isFromPopoverOpen: true,
        isFromYearPickerOpen: true,
      }));
      fromInputRef.current && fromInputRef.current.focus();
    }
    if (element.isFromInput) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isFromPopoverOpen: true,
        isFromYearPickerOpen: true,
      }));
    }
    if (element.isToInput) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isToPopoverOpen: true,
        isToYearPickerOpen: true,
      }));
    }
  };

  // For both popovers
  const onEscAndClickOutside = (element: {
    isFromPopover?: boolean;
    isToPopover?: boolean;
  }) => {
    if (element.isFromPopover) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isFromPopoverOpen: false,
        isFromYearPickerOpen: false,
        isFromMonthPickerOpen: false,
      }));
    }
    if (element.isToPopover) {
      setDateRangeInputState((prevState) => ({
        ...prevState,
        isToPopoverOpen: false,
        isToYearPickerOpen: false,
        isToMonthPickerOpen: false,
      }));
    }
  };

  return (
    <Box>
      <TextInputBox
        disableContentPaddingRight
        variant={"standard"}
        wrapperStyle={
          error
            ? {
                border: `1px solid ${cssColor("--lhds-color-red-500")}`,
                background: cssColor("--lhds-color-red-50"),
              }
            : undefined
        }
        contentRight={
          <Row alignItems={"center"}>
            <Indent num={0.5} flexDirection={"row"}>
              <FlatButton
                leftIcon={stenaCalendar}
                onFocus={() => onFocus({ isCalendarButton: true })}
                onClick={() => onClick({ isCalendarButton: true })}
                size={"small"}
              />
            </Indent>
          </Row>
        }
      >
        <Box width={"100%"} borderBottom={borderBottomFromInput}>
          <Popover
            key={"popoverFrom"}
            visible={dateRangeInputState.isFromPopoverOpen}
            placement={"bottom"}
            onClickOutside={() => onEscAndClickOutside({ isFromPopover: true })}
            content={[
              dateRangeInputState.isFromPopoverOpen &&
                dateRangeInputState.isFromYearPickerOpen && (
                  <YearPicker
                    key={"yearPickerFrom"}
                    value={
                      date.yearFrom === ""
                        ? new Date().getUTCFullYear()
                        : Number(date.yearFrom)
                    }
                    onValueChange={(yearFrom) =>
                      onValueChangePickers(
                        { yearFrom },
                        { isFromYearPicker: true }
                      )
                    }
                  />
                ),
              dateRangeInputState.isFromPopoverOpen &&
                !dateRangeInputState.isFromYearPickerOpen &&
                dateRangeInputState.isFromMonthPickerOpen && (
                  <MonthPicker
                    key={"monthPickerFrom"}
                    value={Number(date.monthFrom) - 1}
                    onValueChange={(monthFrom) =>
                      onValueChangePickers(
                        { monthFrom: monthFrom + 1 },
                        { isFromMonthPicker: true }
                      )
                    }
                  />
                ),
            ]}
          >
            <TextInput
              className={"from_date_input"}
              inputRef={fromInputRef}
              hideBorder
              maxLength={7}
              onKeyDown={handleKeysDown}
              onEsc={() => onEscAndClickOutside({ isFromPopover: true })}
              value={textInputValue({ isFromInput: true })}
              onClick={() => onClick({ isFromInput: true })}
              onValueChange={(value) => {
                onValueChangeInput({ isFromInput: true }, value);
              }}
              onFocus={() => onFocus({ isFromInput: true })}
            />
          </Popover>
        </Box>
        <Row indent={0.5} alignItems={"center"} justifyContent={"center"}>
          <Icon
            icon={stenaArrowWideRight}
            size={12}
            color={cssColor("--lhds-color-ui-500")}
          />
        </Row>
        <Box width={"100%"} borderBottom={borderBottomToInput}>
          <Popover
            key={"popoverTo"}
            visible={dateRangeInputState.isToPopoverOpen}
            placement={"bottom"}
            onClickOutside={() => onEscAndClickOutside({ isToPopover: true })}
            content={[
              dateRangeInputState.isToPopoverOpen &&
                dateRangeInputState.isToYearPickerOpen && (
                  <YearPicker
                    key={"yearPickerTo"}
                    value={
                      date.yearTo === ""
                        ? new Date().getUTCFullYear()
                        : Number(date.yearTo)
                    }
                    onValueChange={(yearTo) =>
                      onValueChangePickers({ yearTo }, { isToYearPicker: true })
                    }
                  />
                ),
              dateRangeInputState.isToPopoverOpen &&
                !dateRangeInputState.isToYearPickerOpen &&
                dateRangeInputState.isToMonthPickerOpen && (
                  <MonthPicker
                    key={"monthPickerTo"}
                    value={Number(date.monthTo) - 1}
                    onValueChange={(monthTo) =>
                      onValueChangePickers(
                        { monthTo: monthTo + 1 },
                        { isToMonthPicker: true }
                      )
                    }
                  />
                ),
            ]}
          >
            <TextInput
              className={"to_date_input"}
              inputRef={toInputRef}
              hideBorder
              maxLength={7}
              onKeyDown={handleKeysDown}
              onEsc={() => onEscAndClickOutside({ isToPopover: true })}
              value={textInputValue({ isToInput: true })}
              onClick={() => onClick({ isToInput: true })}
              onValueChange={(value) => {
                onValueChangeInput({ isToInput: true }, value);
              }}
              onFocus={() => onFocus({ isToInput: true })}
            />
          </Popover>
        </Box>
      </TextInputBox>
      {error && (
        <>
          <Spacing num={0.5} />
          <Row>
            <Txt color={cssColor("--lhds-color-red-500")}>{errorText}</Txt>
          </Row>
        </>
      )}
    </Box>
  );
};
