import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Crumb as WebuiCrumb } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import styles from "./Crumb.module.css";

interface Props {
  label: string;
  path?: string;
  isLast?: boolean;
  dataTestid?: string;
}

export const Crumb: React.FC<Props> = ({ label, path, isLast, dataTestid }) => {
  if (!isLast) {
    const labelUpper = label.toUpperCase();
    return (
      <div data-testid={dataTestid}>
        <WebuiCrumb
          label={labelUpper}
          render={
            path
              ? (props) => (
                  <RouterLink {...props} to={path} className={styles.crumb} />
                )
              : undefined
          }
        />
      </div>
    );
  }
  return (
    <WebuiCrumb
      label={label.toUpperCase()}
      render={(props) => (
        <span
          style={{
            color: cssColor("--lhds-color-ui-600"),
            fontSize: "1rem",
            fontFamily: "var(--swui-font-primary)",
            fontWeight: "var(--swui-font-weight-text-bold)",
            letterSpacing: "0.1rem",
            textDecoration: "none",
          }}
          {...props}
        />
      )}
    />
  );
};
