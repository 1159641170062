import { Row } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";
import React from "react";
import { noShowLateHandlingPercentageString } from "../invoice-specification/utils/utils";
import { InfoPair } from "./InfoPair";

interface Props {
  quantity: number;
  noIncluded: number;
  noExcluded: number;
  noShippedUnits: number;
}

export const InfoPairRow: React.FC<Props> = ({
  quantity,
  noIncluded,
  noExcluded,
  noShippedUnits,
}) => {
  return (
    <Row
      background={cssColor("--lhds-color-ui-300")}
      minHeight={"48px"}
      indent={2}
      alignItems={"center"}
      borderRadius={"var(--swui-border-radius-small)"}
    >
      <InfoPair
        leftContent={"Qty"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterRightContent={1.5}
        borderBottom={false}
        rightContent={quantity}
      />
      <InfoPair
        leftContent={"Included"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterRightContent={1.5}
        borderBottom={false}
        rightContent={noIncluded}
      />
      <InfoPair
        leftContent={"Excluded"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterRightContent={1.5}
        borderBottom={false}
        rightContent={noExcluded}
      />

      <InfoPair
        leftContent={"Shipped units"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterRightContent={1.5}
        borderBottom={false}
        rightContent={noShippedUnits}
      />
      <InfoPair
        leftContent={"Percent"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterRightContent={1.5}
        borderBottom={false}
        rightContent={`${noShowLateHandlingPercentageString(
          noIncluded,
          noShippedUnits
        )}%`}
      />
    </Row>
  );
};
