import { Box, SeparatorLine, Spacing } from "@stenajs-webui/core";
import { CardHeader } from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import React, { ReactNode } from "react";
import { noShowRouteBookingTableConfig } from "../config/RouteBookingTableConfig";
import { RoutePairData } from "../transformers/InvoiceSpecificationDataToHandlingTypeDataTransformer";
import { getConsignmentNo } from "../utils/utils";
import { ViewSpecificationRoutePairHeading } from "./ViewSpecificationRoutePairHeading";
import { InfoPairRow } from "../../info-pair/InfoPairRow";
import { cssColor } from "@stenajs-webui/theme";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { InfoPair } from "../../info-pair/InfoPair";

interface Props {
  routePairsData: RoutePairData[];
  renderBookingLink?: (bookingNo: string) => ReactNode;
}

export const ViewSpecificationNoShowSummaryAndRoutePairTableWrapper: React.FC<
  Props
> = ({ routePairsData, renderBookingLink }) => {
  return (
    <div className="table-wrapper">
      <CardHeader
        text={"No show"}
        contentAfterHeading={
          <InfoPair
            leftContent={"Consignment"}
            leftTextColor={cssColor("--lhds-color-ui-500")}
            leftTextSize={"small"}
            leftTextWhiteSpace={"nowrap"}
            rightTextSize={"small"}
            rightTextWhiteSpace={"nowrap"}
            rightTextColor={cssColor("--lhds-color-ui-700")}
            indentAfterRightContent={1.5}
            borderBottom={false}
            rightContent={getConsignmentNo(routePairsData)}
          />
        }
      />
      <Spacing num={SpacingValues.TWELVE} />
      {routePairsData
        .sort((a, b) => {
          if (a.summary.routePairCode && b.summary.routePairCode) {
            return a.summary.routePairCode.localeCompare(
              b.summary.routePairCode
            );
          }
          return 0;
        })
        .map((routePairData) => {
          const { summary, bookings } = routePairData;

          if (bookings.length === 0) {
            return null;
          }

          return (
            <Box
              className={"public-page-break-wrapper"}
              indent={IndentValues.TWENTYFOUR}
              key={`view-specification-noshow-table-wrapper-${summary.routePairCode}`}
            >
              {summary.routePairDescription && (
                <ViewSpecificationRoutePairHeading
                  heading={summary.routePairDescription}
                />
              )}
              <Spacing />
              <InfoPairRow
                noExcluded={summary.noExcludedBookings}
                noShippedUnits={summary.shippedUnits}
                noIncluded={
                  summary.noOfNoShowLateHandling - summary.noExcludedBookings
                }
                quantity={summary.noOfNoShowLateHandling}
              />
              <Spacing />
              <StandardTable
                config={noShowRouteBookingTableConfig(
                  summary.invoiceCurrencyCode,
                  renderBookingLink
                )}
                items={bookings}
                variant={"condensed"}
              />
              <SeparatorLine
                width="0.5px"
                color={cssColor("--lhds-color-ui-300")}
              />
              <Spacing num={SpacingValues.TWELVE} />
            </Box>
          );
        })}
    </div>
  );
};
